@import "@/styles/var.module.scss";

.container {
  // display: flex;
  .quoteList {
    height: 5rem;
    margin: 0.5rem 0 1.5rem;
  }
  .skeleton {
    width: 10rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: #555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: 1s infinite alternate ease-out breathing-color;
    padding: 0.5rem;

    > div {
      background-color: #aaa;
      border-radius: 0.25rem;
    }
    .symbol {
      height: 1rem;
      width: 6rem;
    }
    .price {
      height: 1.5rem;
      width: 8rem;
    }
    .fiat {
      height: 0.75rem;
      width: 4rem;
    }
  }

  .quoteRow {
    display: flex;
    width: 80%;
    height: 5rem;
    margin: 0.5rem 0;
    justify-content: space-between;
    text-align: left;

    p {
      margin: 0;
    }

    .symbol {
      font-size: 16px;
      font-weight: 400;
    }
    .priceRate {
      color: #D94549;
    }
    .priceRate.rise {
      color: #01A4FF;
    }
    .price {
      font-size: 24px;
      font-weight: 500;
    }
    .fiat {
      color: #babdc2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0.5rem 0;
    

    p {
      margin: 0;
    }
    .data {
      font-size: 36px;
      font-weight: 47px;
      color: #EEEEEE;
      margin-bottom: 10px;
      font-family: IBM_Plex_Sans_Medium;
    }
    .label {
      // font: normal normal normal 18px/23px IBM Plex Sans;
      font-size: 14px;
      font-weight: 18px;
      color: #7C7E87;
    }
  }
}

@keyframes breathing-color {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .info {
      grid-template-columns: 1fr 1fr !important;
      gap: 30px;
      .data {
        font-size: 17px;
      }
      .label {
        font-size: 12px;
      }
    }
  }
}
