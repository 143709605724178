@import '@/styles/var.module.scss';

.container {
  .quoteList {
    height: 5rem;
    margin: 0.5rem 0 1.5rem;
  }
  .skeleton {
    width: 10rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: #555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: 1s infinite alternate ease-out breathing-color;

    padding: 0.5rem;

    > div {
      background-color: #aaa;
      border-radius: 0.25rem;
    }
    .symbol {
      height: 1rem;
      width: 6rem;
    }
    .price {
      height: 1.5rem;
      width: 8rem;
    }
    .fiat {
      height: 0.75rem;
      width: 4rem;
    }
  }

  .quoteRow {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-between;
    gap: 1rem;
    text-align: left;
    overflow: hidden;
    .quoteBrick {
      border-radius: 3rem;
      background-color: #12141C;
      padding: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: rgba(246, 249, 254, .1);
      }
    }
    .datas {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 1rem;
        width: 44px;
      }
      p {
        margin: 0;
        display: flex;
        flex-direction: column;
      }
      .symbol {
        font-size: 16px;
        font-weight: 400;
      }
      .priceRate {
        color: #f84960;
        width: 100%;
        margin-right: 1rem;
      }
      .priceRate.rise {
        color: #01A4FF;
        width: 100%;
      }
      .price {
        display: flex;
        gap: 2rem;
        color: #babdc2;
        font-size: 14px;
        font-weight: 400;
        width: 50%;
      }
    }
  }

  .quoteColumn {
    width: 100%;
    text-align: left;
    .quoteBrick {
      border-radius: 5rem;
      background-color: #12141C; 
      padding: 0.2rem 1rem;
      margin: 1rem 0;
      height: 4rem;
      &:hover {
        cursor: pointer;
        background-color: rgba(246, 249, 254, .1);
      }
    }
    .datas {
      width: 100%;
      display: flex;
      margin: 0.5rem 0;
      .tradingData {
        width: 100%;
        display: flex;
        align-items: center;
        .title {
          width: 80px;
        }
        .price {
          width: 130px;
        }
        .rate {
          width: 100px;
          color: #f84960;
        }
        .rate.rise {
          color: #01A4FF;
        }
        .rate.grey {
          color: var(--sg-grey1);
        }
      }

      img {
        float: left;
        margin-right: 20px;
        height: 30px;
        width: 30px;
        margin-top: 5px;
      }
      .markets {
        p {
          margin: 0;
        }
        .symbol {
          font-size: 16px;
          font-weight: 400;
        }
        .priceRate {
          color: #D94549;
          float: right;
        }
        .priceRate.rise {
          color: #01A4FF;
          float: right;
        }
        .price {
          color: #babdc2;
          font-size: 14px;
          font-weight: 400;
          width: 150px;
        }
      }
    }
  }

  .quoteColumnSmall {
    font-family: IBM_Plex_Sans_Normal;
    font-size: 14px;
    width: 100%;
    text-align: left;
    .quoteBrick {
      width: 100%;
    }
    .datas {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .nameLabel {
        flex: 0.4 1;
        display: inline-flex;
        align-items: center;
        text-wrap: nowrap;
        img {
          float: left;
          margin-right: 11px;
          height: 24px;
          width: 24px;
        }
      }
      .priceLabel {
        flex: 0.25 1;
        text-align: right;
      }
      .changeLabel {
        flex: 0.35 1;
        text-align: right;
        .priceRate {
          color: #f84960;
          float: right;
        }
        .priceRate.rise {
          color: #01A4FF;
          float: right;
        }
      }
    }
  }

  .tradingData {
    width: 95%;
  }
}

@keyframes breathing-color {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .quoteRow {
      .datas {
        img {
          width: 32px;
        }
        .symbol {
          font-size: 14px;
        }
        .price {
          gap: 8px;
          font-size: 12px;
        }
      }
    }
  }
}
