.container {
  margin: 0 1rem 1.5rem;
  padding: 1rem 0;
  border-radius: 10px;
  background-color: #1e2129;
  .title {
    position: relative;
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #777e9088;
    &::after {
      content: '';
      height: 3px;
      width: 24px;
      position: absolute;
      bottom: 0;
      background: #ffd140;
      border-radius: 1px;
      left: calc(50% - 12px);
    }
  }

  .table {
    width: 100%;
    font-family: DIN-Medium;
    border-spacing: 0;

    .header {
      > th {
        font-weight: 400;
        font-size: 0.875rem;
        &:first-of-type {
          padding-left: 1rem;
        }
        &:last-of-type {
          padding-right: 1rem;
        }
      }
    }

    tr {
      height: 3rem;
    }
    th {
      color: #777e90;
      height: 3rem;
      text-align: right;
    }
    th:nth-of-type(2) {
      text-align: center;
    }
    th:first-of-type {
      text-align: left;
    }

    .skeleton {
      > td {
        height: 3.5rem;
        > span {
          display: block;
          height: 1.5rem;
          border-radius: 0.5rem;
          background-color: #555;
          animation: 1s infinite alternate ease-out breathing-color;
        }

        &:first-of-type {
          padding-left: 1rem;
        }
        &:last-of-type {
          padding-right: 1rem;
        }
      }
      .name {
        width: 5rem;
        margin-right: auto;
      }
      .chart {
        width: 10rem;
        margin: auto;
      }
      .rate {
        width: 5rem;
        margin-left: auto;
      }
      .price {
        width: 5rem;
        margin-left: auto;
      }
    }

    .quoteRow {
      cursor: pointer;
      background-color: rgba(#555, 0);
      transition: background-color 0.3s ease-out;

      &:hover {
        border-radius: 1rem;
        background-color: rgba(#555, 0.2);
      }

      td {
        &:first-of-type {
          padding-left: 1rem;
        }
        &:last-of-type {
          padding-right: 1rem;
        }
      }

      .name {
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        min-width: 10rem;
        > figure {
          display: inline-block;
          position: relative;
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.75rem 0 0;
          transform: translateY(0.375rem);
        }
        > .fallbackIcon {
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.75rem 0 0;
          background-color: gray;
          border-radius: 50%;
          text-align: center;
          line-height: 1.5;
        }
      }
      .chart {
        width: 10rem;
      }
      .rate {
        color: #D94549;
        text-align: right;
        min-width: 6rem;
      }
      .rate.raise {
        color: #01A4FF;
      }
      .price {
        text-align: right;
        font-weight: 400;
        font-size: 16px;
        min-width: 6rem;
      }
    }
  }
}

@keyframes breathing-color {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}
