.container {
  .quoteList {
    height: 5rem;
    margin: 0.5rem 0 1.5rem;
  }
  .skeleton {
    width: 10rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: #555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: 1s infinite alternate ease-out breathing-color;

    padding: 0.5rem;

    > div {
      background-color: #aaa;
      border-radius: 0.25rem;
    }
    .symbol {
      height: 1rem;
      width: 6rem;
    }
    .price {
      height: 1.5rem;
      width: 8rem;
    }
    .fiat {
      height: 0.75rem;
      width: 4rem;
    }
  }

  .quoteRow {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: space-between;
    gap: 1rem;
    text-align: left;
    overflow: hidden;
    .quoteBrick {
      border-radius: 3rem;
      background-color: #12141C;
      padding: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: rgba(246, 249, 254, .1);
      }
    }
    .datas {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        margin-right: 1rem;
        width: 44px;
      }
      p {
        margin: 0;
        display: flex;
        flex-direction: column;
      }
      .symbol {
        font-size: 16px;
        font-weight: 400;
      }
      .priceRate {
        color: #D94549;
        width: 100%;
        margin-right: 1rem;
      }
      .priceRate.rise {
        color: #01A4FF;
        width: 100%;
      }
      .price {
        display: flex;
        gap: 2rem;
        color: #babdc2;
        font-size: 14px;
        font-weight: 400;
        width: 50%;
      }
    }
  }
}

@keyframes breathing-color {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .quoteList {
      height: fit-content;
      margin: 0;
    }
    .quoteRow {
      margin-top: 0;
      .datas {
        img {
          width: 32px;
        }
        .symbol {
          font-size: 14px;
        }
        .price {
          gap: 8px;
          font-size: 12px;
        }
      }
    }
  }
}
