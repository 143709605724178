.container {
    display: flex;
    justify-content: center;

    .promotionCard {
        width: 300px;
        margin-bottom: 2rem;
        img {
            width: 100%;
        }
    }
}
