@import "@/styles/var.module.scss";

.container {
  overflow: hidden;
  .quoteList {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .skeleton {
    width: 10rem;
    height: 5rem;
    border-radius: 0.5rem;
    background-color: #555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: 1s infinite alternate ease-out breathing-color;

    padding: 0.5rem;

    > div {
      background-color: #aaa;
      border-radius: 0.25rem;
    }
    .symbol {
      height: 1rem;
      width: 6rem;
    }
    .price {
      height: 1.5rem;
      width: 8rem;
    }
    .fiat {
      height: 0.75rem;
      width: 4rem;
    }
  }

  .marquee{
    display: flex;
    overflow: hidden;
    width: 100vw;
    .rotateMe {
      display: flex;
      animation: marquee 180s linear infinite;
      animation-delay: 3s;
      gap: 15px;
      &:hover {
        animation-play-state: paused;
      }
    }
    .rotateMeDbl {
      @extend .rotateMe;
      animation: marquee 360s linear infinite;
    }
  }

  .quoteBrick {
    border-radius: 3rem;
    background-color: #12141C;
    padding: 0.5rem;
    &:hover {
      cursor: pointer;
      background-color: rgba(246, 249, 254, .1);
    }
  }
  .datas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 1rem;
      width: 44px;
    }
    p {
      margin: 0;
      display: flex;
      flex-direction: column;
    }
    .symbol {
      font-size: 16px;
      font-weight: 400;
    }
    .priceRate {
      color: #D94549;
      width: 100%;
      margin-right: 1rem;
    }
    .priceRate.rise {
      color: #01A4FF;
      width: 100%;
    }
    .price {
      display: flex;
      gap: 2rem;
      color: #babdc2;
      font-size: 14px;
      font-weight: 400;
      width: 50%;
    }
  }
  
}

@keyframes breathing-color {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0.2;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .datas {
      img {
        width: 32px;
      }
      .symbol {
        font-size: 14px;
      }
      .price {
        gap: 8px;
        font-size: 12px;
      }
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate(0%, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}
