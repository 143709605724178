.container {
  padding: 1rem;
  .licenseFrame {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181a20;
    cursor: pointer;
    height: 176px;
    width: 100%;
    .license {
      margin: 0;
      position: relative;
      width: 204px;
      height: 144px;
    }
    .vertical {
      width: 110px;
      height: 144px;
    }
  }

  .country {
    text-align: start;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #494e5b;
    > span {
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      color: #777e90;
      padding: 3px 6px;
      border: 1px solid #777e90;
      border-radius: 4px;
      margin-left: 14px;
    }
  }

  .licenseInfo {
    display: flex;
    justify-content: space-between;
    position: relative;

    > p {
      margin: 0;
      padding: 0;
      flex: 1;
      text-align: start;

      > span {
        display: block;
        color: #777e90;
      }

      > span:last-of-type {
        color: #fff;
      }
    }

    > p:last-of-type {
      padding-left: 1.5rem;
    }
    ::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(50% - 0.5px);
      width: 1px;
      background-color: #494e5b;
    }
  }

  .link {
    margin-top: 1rem;
    width: 100%;
    border: 1px solid #ffd140;
    color: #ffd140;
  }
  .imageView {
    display: none;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0.25rem);
    background-color: rgba(#181a20, 0.2);
    justify-content: center;
    align-items: center;

    > figure {
      width: 80%;
      height: 90%;
      position: relative;
      cursor: pointer;
    }
  }
  .modelShow {
    display: flex;
    > figure {
      animation: zoom 0.3s;
    }
  }
}
@keyframes zoom {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
