.container {
  display: flex;
  align-items: center;
  width: 80%;
  
  a:nth-child(2) {
    width: 60%;
  }
  a:nth-child(3) {
    width: 30%;
  }
}
.container:nth-child(2){
  a:nth-child(2), a:nth-child(3), a:nth-child(4) {
    opacity: 0.5;
  }
}
.container:nth-child(3){
  a:nth-child(2), a:nth-child(3), a:nth-child(4) {
    opacity: 0.2;
  }
}

// $animation-timing: 12s;
// $carousel-items: 4;
// $animation-delay-fraction: $animation-timing / $carousel-items;
// $animation-steps-fraction: 100 / $carousel-items;
// $slide-change-timing: 4; // percentage of total animation cycle

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 200px;

  .carouselItem {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    filter: drop-shadow(0 2px 2px #555);
    will-change: transform, opacity;
    cursor: pointer;
  }

  &.carousel:hover {
    .carouselItem {
     animation-play-state: paused;
    }
  }

  &.carousel4 {
    $animation-timing: 12s;
    $carousel-items: 4;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 9; // percentage of total animation cycle
    
    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical4 $animation-timing linear infinite;
    }
  
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical4 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }

  &.carousel5 {
    $animation-timing: 15s;
    $carousel-items: 5;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 8; // percentage of total animation cycle

    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical5 $animation-timing linear infinite;
    }
    
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical5 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }

  &.carousel6 {
    $animation-timing: 18s;
    $carousel-items: 6;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 7; // percentage of total animation cycle

    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical6 $animation-timing linear infinite;
    }
    
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical6 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }

  &.carousel7 {
    $animation-timing: 21s;
    $carousel-items: 7;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 6; // percentage of total animation cycle

    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical7 $animation-timing linear infinite;
    }
    
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical7 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }

  &.carousel8 {
    $animation-timing: 24s;
    $carousel-items: 8;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 5; // percentage of total animation cycle

    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical8 $animation-timing linear infinite;
    }
    
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical8 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }

  &.carousel9 {
    $animation-timing: 27s;
    $carousel-items: 9;
    $animation-delay-fraction: $animation-timing / $carousel-items;
    $animation-steps-fraction: 100 / $carousel-items;
    $slide-change-timing: 4; // percentage of total animation cycle

    .carouselItem {
      opacity: 0;
      animation: carousel-animate-vertical9 $animation-timing linear infinite;
    }
    
    .carouselItem:last-child {
      animation-delay: calc(-#{$animation-delay-fraction} * 2);
    }

    @for $i from 1 through ($carousel-items - 1) {
      .carouselItem:nth-child(#{$i}) {
        animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
      }
    }
    
    @keyframes carousel-animate-vertical9 {
      0% {
        transform: translateY(200%) scale(1);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      #{$slide-change-timing}%,
      #{$animation-steps-fraction}% {
        transform: translateY(100%) scale(1);
        opacity: 0.4;
        visibility: visible;
        z-index: 50;
      }
      #{$animation-steps-fraction + $slide-change-timing}%,
      #{$animation-steps-fraction * 2}% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
        visibility: visible;
        z-index: 75;
      }
      #{($animation-steps-fraction * 2) + $slide-change-timing}%,
      #{$animation-steps-fraction * 3}% {
        transform: translateY(-100%) scale(1);
        opacity: 1;
        visibility: visible;
        z-index: 100;
      }
      #{($animation-steps-fraction * 3) + $slide-change-timing}% {
        transform: translateY(-200%) scale(1);
        opacity: 0;
        z-index: 0;
        visibility: visible;
      }
      100% {
        transform: translateY(-100%) scale(0.5);
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
    }
  }
}

.carouselHead {
  border-radius: 50%;
  // background-color: #d7f7fc;
  padding: 14px 14px 14px 0px;
  position: relative;
  margin-right: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselBody {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  // background-color: var(--sg-grey1);
  padding: 8px 0px 8px 40px;
  .indexTitle {
    width: 800px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
  }
}

.index {
  display: flex;
  > div {
    margin-right: 3rem;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .carousel {
    font-size: 12px;
    height: 120px;
    .carouselHead {
      padding: 0px 14px 0px 0;
      svg {
        width: 20px;
      }
    }
    .carouselBody {
      .indexTitle {
        width: 500px;
      }
    }
    .index {
      display: flex;
      > div {
        margin-right: 0;
      }
      .more {
        display: none;
      }
    }
  }
}
